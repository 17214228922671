<template>
  <div class="container weddings pb-8 fadeInFast">
    <HeadlineTableView
        :icon="'mdi-ring'"
        :headline="'Hochzeiten'"
        :button-icon="'mdi-ring'"
        :button-text="'Neue Hochzeit'"
        :entity="'wedding'"
        @buttonClick="openDialogCreateWedding()"
    ></HeadlineTableView>

    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>
    <v-card flat class="rounded-xl pa-2 pa-sm-4">
      <v-tabs show-arrows v-model="register" class="pb-6">
        <v-tab>
          Board
        </v-tab>
        <v-tab>
          Liste
        </v-tab>
        <v-tab>
          Archiv
        </v-tab>
      </v-tabs>
      <div v-if="loaded">
        <DraggableView v-if="register===0" :totalLength="weddings.length" @changeTab="register=1"></DraggableView>
        <TableView
            v-if="register===1"
            :headLines="headLines"
            :rows="weddings"
            :textNoResults="'Hier werden deine offenen Hochzeiten angezeigt.'"
            :archiveEnabled="true"
            @archiveEntries="archiveWeddings"
            @deleteEntries="deleteWeddings"
            @openDetail="openDetailWedding"
        ></TableView>
        <TableView
            v-if="register===2"
            :headLines="headLines"
            :rows="weddingsClosed"
            :textNoResults="'Hier werden deine archivierten Hochzeiten angezeigt.'"
            @deleteEntries="deleteWeddings"
            @openDetail="openDetailWedding"
        ></TableView>
      </div>
      <Spinner v-else></Spinner>

      <CreateWedding
          :dialog="dialog"
          :openAfterCreate="true"
          @hideDialog="hideDialog"
      ></CreateWedding>
    </v-card>
  </div>
</template>
<script>

import {mapGetters} from "vuex"
import {error, msgObj, success} from "@/helper/helper";

const Message = () => import("@/components/generalUI/Message");
const TableView = () => import("@/components/tableView/TableView");
const HeadlineTableView = () => import("@/components/tableView/HeadlineTableView");
const CreateWedding = () => import("@/components/dialog/createDialogs/CreateWedding");
const DraggableView = () => import("@/components/entities/wedding/DraggableView");
const Spinner = () => import("@/components/generalUI/Spinner");
const delay = ms => new Promise(res => setTimeout(res, ms));

export default {
  name: 'Weddings',
  components: {
    TableView,
    Message,
    HeadlineTableView,
    CreateWedding,
    DraggableView,
    Spinner
  },
  mounted() {
    window.scrollTo(0, 0)
    this.$store.dispatch('auth/checkLogin', {}).then((res) => {
      this.$store.dispatch('wedding/setWeddingsEmpty').then(() => {
        this.$store.dispatch('wedding/getWeddings', {
          uid: res
        }).then(() => {
          this.loaded = true
          this.$store.dispatch('settings/getWeddingLabels', {
          }).catch((err) => {
            this.message = error(err)
          })

          }).catch((err) => {
          this.message = error(err)
        })
      }).catch((err) => {
        this.message = error(err)
      })
    })
  },
  computed: {
    ...mapGetters('auth', {
      user: 'user',
    }),
    ...mapGetters('wedding', {
      weddings: 'weddings',
      weddingsClosed: 'weddingsClosed',
    }),

  },
  data() {
    return {
      weddingsO: [],
      weddingsC: [],
      register: 0,
      loaded: false,
      dialog: false,
      message: msgObj(),
      headLines: [
        {text: "Hochzeit von", sort: 'customerName'},
        {text: "Hochzeitsdatum", sort: 'wedDate'},
        {text: "Status", sort: 'status'},
        {text: "Typ", sort: 'type'},
      ],
    }
  },
  methods: {
    openDialogCreateWedding() {
      this.dialog = true
    },
    openDetailWedding(id) {
      this.$router.push("weddings/wedding/" + id)
    },
    hideDialog() {
      this.dialog = false
    },
    async deleteWeddings(ids) {
      let i = 0
      this.loaded = false
      for (let id of ids) {
        this.$store.dispatch('wedding/deleteWedding', {
          uid: this.user.id,
          weddingId: id,
        }).catch((err) => {
          this.message = error(err)
        }).finally(() => {
          if (i === ids.length - 1) {
            this.loaded = true
            if (ids.length === 1) {
              this.message = success("Hochzeit erfolgreich gelöscht.")
            } else {
              this.message = success(ids.length + "Hochzeiten erfolgreich gelöscht.")
            }
          }
        })
        await delay(100)
        i++
      }
    },
    async archiveWeddings(ids) {
      let i = 0
      this.loaded = false
      for (let id of ids) {
        this.$store.dispatch('customer/editField', {
          uid: this.user.id,
          row: 'isArchived',
          newValue: true,
          id,
          entity: 'wedding'
        }).catch((err) => {
          this.message = error(err)
        }).finally(() => {
          if (i === ids.length - 1) {
            this.$store.dispatch('wedding/setWeddingsEmpty').then(() => {
              this.$store.dispatch('wedding/getWeddings', {
                uid: this.user.id
              }).then(() => {
                this.loaded = true
                if (ids.length === 1) {
                  this.message = success("Hochzeit erfolgreich archiviert.")
                } else {
                  this.message = success(ids.length + " Hochzeiten erfolgreich archiviert.")
                }
              }).catch((err) => {
                this.message = error(err)
              })
            }).catch((err) => {
              this.message = error(err)
            })
          }
        })
        await delay(100)
        i++
      }
    },
  }
}
</script>
<style>
</style>
